import { useEffect } from 'react';

import { type GameChangeSubmitterNotification } from '../../../types';
import { XIcon } from '../../icons/XIcon';
import { useParticipant } from '../../Player';
import { useNotificationDataSource } from '../Context';
import { type BaseNotificationProps } from '../type';

const GameChangeSubmitterNotificationView = (
  props: BaseNotificationProps & {
    notification: GameChangeSubmitterNotification;
  }
): JSX.Element => {
  const notificationDataSource = useNotificationDataSource();

  const droppedSubmitter = useParticipant(
    props.notification.metadata.droppedPlayerClientId
  );

  const newSubmitter = useParticipant(
    props.notification.metadata.newPlayerClientId
  );

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    notificationDataSource.dismiss(props.notification.id);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      notificationDataSource.dismiss(props.notification.id);
    }, 10000);

    return () => {
      clearTimeout(id);
    };
  }, [notificationDataSource, props.notification.id]);

  return (
    <div
      className='
        w-166 h-32
        text-main-layer
        rounded-xl text-xl font-extralight
        bg-[#FBB707]
    '
    >
      <div className='flex flex-row w-full h-full items-center justify-center px-8'>
        <div className='flex flex-col w-full h-full items-start justify-center font-normal'>
          <div>
            {' '}
            Oops! Looks like{' '}
            {droppedSubmitter?.firstName ?? 'current submitter'} dropped out.
          </div>
          <div>
            <span className='inline font-bold'>{newSubmitter?.firstName}</span>{' '}
            you’re in the hot seat now to answer this question!
          </div>
        </div>

        <div
          className='absolute text-center w-5 h-5 top-1 right-0.5 rounded-full cursor-pointer'
          onClick={handleClose}
        >
          <XIcon />
        </div>
      </div>
    </div>
  );
};

// Required for lazy import
// eslint-disable-next-line import/no-default-export
export default GameChangeSubmitterNotificationView;
